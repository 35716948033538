import React, { useEffect, useState ,useRef} from "react";
import { Modal } from 'react-bootstrap'
import axios from "axios";
import SearchableDropdown from "../../shared/searchable-dropdown";
import './sharechat.css';
import Toaster from "../../shared/toaster";

import Select from 'react-select';
import Spinner from '../../shared/spinner';
const ShareChat = (props) => {
 
  const [isShow, invokeModal] = useState(false);
  const [isSelectedFolder, setIsSelectedFolder] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [value, setValue] = useState("Select option...");
  const [listOfUsers, setListOfUsers] = useState([]);
  const userId = sessionStorage.getItem('user_id');
 const [groupVectorId, setGroupVectorId] = useState("");
  const [isShareDisabled, setIsShareDisabled] = useState(false);
   const [pageLoader, setPageLoader] = useState(false);
  
 const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
    const [isRtl, setIsRtl] = useState(false);

   

  useEffect(() => {
    props.callShareChatModel.current = handleModel; 
    console.log("fetchProps", props.promptData);
   // console.log("props", props);
        
    //need to call api for get userlist 
    userList();
   

      }, [ props.promptData])
    
  const handleModel = (flag = true) => {
    return invokeModal(flag);
  };

   const submitForm = async(event) => {
     event.preventDefault();
    // if (window.confirm("Do you really want to share the chat?"))
    // { 
      setPageLoader(true);
      let url = process.env.REACT_APP_SHARE_TOPIC_URL;       
      console.log("url", url);
      console.log("value", value);
     
      let  gettopicid = props.promptData.topic_id;
     // const groupId=any;
     let xx="";
     try {
      const response1 = await axios.get(`${process.env.REACT_APP_BASE_URL}/topic/${gettopicid}/group`);
      console.log('First API data:', response1.data);
      xx=response1.data['group_vector_id'];
      console.log("xx", xx); 
      setGroupVectorId(xx);
      console.log("groupVectorId", xx); 

      } catch (e) {
      console.log('Error',e)
      }
     
     console.log("groupVectorId", groupVectorId); 

     
     if (value == null || value == "") {
          Toaster({ type: 'error', message: 'Please select a user to share chat.' });
          setPageLoader(false);
     }
     else
     {
       if (window.confirm("Do you really want to share the chat?"))
     { 

        let to_user_id = value['user_id'];
        console.log("to_user_id", to_user_id); 
        const dt = new Date().getTime();
        let dest_topic_id = dt;
        console.log("groupVectorId ", groupVectorId); 
        let llm_id= sessionStorage.getItem('session_model');
        console.log("llm_id ", llm_id);    
        let  topic_id = props.promptData.topic_id;
        console.log("topic_id ", topic_id); 
       // setPageLoader(true);

      
     
        const response = axios
            .post(url, {
                user_id: userId,
                topic_id: topic_id,
                to_user_id: to_user_id,
                dest_topic_id: dt,
                llm_id: llm_id,
                group_vector_id:xx

            })
          .then((response) => {
               console.log("response ", response); 
                // props.handleNewPromptCallback(props.promptFlag);
                 setPageLoader(false);
                // handleModel(false);
            Toaster({ type: 'success', message: 'Shared successfully' });
          
       
            props.getUpdatedSharedTopics();
                // setInputField({ ...initialState })
            })
            .catch((error) => {
              setPageLoader(false); 
                console.error('ERROR ', error.message);
                Toaster({ type: 'error', message: error.message });
            });
     }
     else{
      setPageLoader(false);
     }
    }
     
     
    
  }
  
   const userList = () => { 
        
         axios
            .get(process.env.REACT_APP_GET_USERLIST_URL)
            .then((response) => {
               // console.log("User LIST", response.data);    
                 setListOfUsers(response.data);                   
                 })
            .catch((error) => {
                console.error("ERROR ", error);
            });
  }
  
  

   

 
  

    return (
       
        
    //  <Modal show={isShow} scrollable='true' contentClassName="modal-height">
         <Modal show={isShow} >
            <Modal.Header>
                <Modal.Title>Share Chat</Modal.Title>
                <button type="button"
                    className="btn-close"
                    onClick={() => handleModel(false)}
                    title='Close'
                    aria-label="Close">
                </button>
            </Modal.Header>
        <form onSubmit={submitForm}>
         <Modal.Body >
            
       <div style={{height: '50vh'}}>
            {/*  <div className='col-lg-6 '>  */}
         
           
      {/* <SearchableDropdown 
        options={listOfUsers}
        label="user_name"
        id="user_id"
        selectedVal={value}
        handleChange={(val) => setValue(val)}
           /> */}
                       
                        <Select
        // className="basic-single"
        classNamePrefix="select"
        defaultValue={listOfUsers[0]}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name="color"
        options={listOfUsers}
  getOptionLabel={(option)=>option.user_name}
                            getOptionValue={(option) => option.user_id}
 onChange={(val) => setValue(val)}
      />
 </div> 
     
        
                  
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit"
                        className="btn btn-outline-success" disabled={pageLoader}
                       >
                        Share</button>
                    <button type="button"
                        className="btn btn-outline-danger"
                        onClick={() => handleModel(false)}>
                        Cancel
                    </button>
                </Modal.Footer>
        </form>
        
            <Spinner spinnerLoad={pageLoader} />
       </Modal>
       


  
   )

        

}

export default ShareChat;