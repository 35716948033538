// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../public/ProximaNova/ProximaNova-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./../public/ProximaNova/ProximaNova-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./../public/ProximaNova/ProximaNova-BoldIt.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./../public/ProximaNova/ProximaNova-Extrabld.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./../public/ProximaNova/ProximaNova-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./../public/ProximaNova/ProximaNova-LightIt.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./../public/ProximaNova/ProximaNova-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./../public/ProximaNova/ProximaNova-RegularIt.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("./../public/ProximaNova/ProximaNova-Semibold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("./../public/ProximaNova/ProximaNova-SemiboldIt.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: Proxima-Nova-Black;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@font-face {
    font-family: Proxima-Nova-Bold;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
@font-face {
    font-family: Proxima-Nova-BoldIt;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
@font-face {
    font-family: Proxima-Nova-Extrabold;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
@font-face {
    font-family: Proxima-Nova-Light;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}
@font-face {
    font-family: Proxima-Nova-LightIt;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}
@font-face {
    font-family: Proxima-Nova-Regular;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
}
@font-face {
    font-family: Proxima-Nova-RegularIt;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_7___});
}
@font-face {
    font-family: Proxima-Nova-Semibold;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_8___});
}
@font-face {
    font-family: Proxima-Nova-SemiboldIt;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_9___});
}




`, "",{"version":3,"sources":["webpack://./src/fonts.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,4CAAuD;AAC3D;AACA;IACI,8BAA8B;IAC9B,4CAAsD;AAC1D;AACA;IACI,gCAAgC;IAChC,4CAAwD;AAC5D;AACA;IACI,mCAAmC;IACnC,4CAA0D;AAC9D;AACA;IACI,+BAA+B;IAC/B,4CAAuD;AAC3D;AACA;IACI,iCAAiC;IACjC,4CAAyD;AAC7D;AACA;IACI,iCAAiC;IACjC,4CAAyD;AAC7D;AACA;IACI,mCAAmC;IACnC,4CAA2D;AAC/D;AACA;IACI,kCAAkC;IAClC,4CAA0D;AAC9D;AACA;IACI,oCAAoC;IACpC,4CAA4D;AAChE","sourcesContent":["@font-face {\r\n    font-family: Proxima-Nova-Black;\r\n    src: url(./../public/ProximaNova/ProximaNova-Black.ttf);\r\n}\r\n@font-face {\r\n    font-family: Proxima-Nova-Bold;\r\n    src: url(./../public/ProximaNova/ProximaNova-Bold.ttf);\r\n}\r\n@font-face {\r\n    font-family: Proxima-Nova-BoldIt;\r\n    src: url(./../public/ProximaNova/ProximaNova-BoldIt.ttf);\r\n}\r\n@font-face {\r\n    font-family: Proxima-Nova-Extrabold;\r\n    src: url(./../public/ProximaNova/ProximaNova-Extrabld.ttf);\r\n}\r\n@font-face {\r\n    font-family: Proxima-Nova-Light;\r\n    src: url(./../public/ProximaNova/ProximaNova-Light.ttf);\r\n}\r\n@font-face {\r\n    font-family: Proxima-Nova-LightIt;\r\n    src: url(./../public/ProximaNova/ProximaNova-LightIt.ttf);\r\n}\r\n@font-face {\r\n    font-family: Proxima-Nova-Regular;\r\n    src: url(./../public/ProximaNova/ProximaNova-Regular.ttf);\r\n}\r\n@font-face {\r\n    font-family: Proxima-Nova-RegularIt;\r\n    src: url(./../public/ProximaNova/ProximaNova-RegularIt.ttf);\r\n}\r\n@font-face {\r\n    font-family: Proxima-Nova-Semibold;\r\n    src: url(./../public/ProximaNova/ProximaNova-Semibold.ttf);\r\n}\r\n@font-face {\r\n    font-family: Proxima-Nova-SemiboldIt;\r\n    src: url(./../public/ProximaNova/ProximaNova-SemiboldIt.ttf);\r\n}\r\n\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
