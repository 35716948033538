
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { v4 as uuidv4 } from 'uuid';
uuidv4();

const msconfig = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AZURE_REDIRECT_URL,
    redirectUrl: process.env.REACT_APP_BASE_URL
  },
});
 
msconfig.addEventCallback((event) => {
  if (event.eventType == EventType.LOGIN_SUCCESS) {
    msconfig.setActiveAccount(event.payload.account);
  }
});
 
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App msalInstance={msconfig} />
  </React.StrictMode>
);
 
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
