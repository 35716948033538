import ConfigData from "./../../config.json";
import './style.css';
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useRolesContext } from "../../shared/context/roles-context";

const RAGParams = {
    "role_id": 0,
    "user_rag_id": '0',
    "org_rag_id": 0
}

const RAGFileView = (props) => {
    const userId = sessionStorage.getItem('user_id');
    const ragObject = JSON.parse(sessionStorage.getItem('ragObject'));
    const roles = useRolesContext();
    const [isSelectedRole, setIsSelectedRole] = useState(0);
    const [isSelectedFolder, setIsSelectedFolder] = useState('');
    const [orgData, setOrgData] = useState([]);

    useEffect(() => {
        console.log('View - ', roles);
        !!ragObject && setIsSelectedRole(ragObject.role_id);
        groups();
    }, []);

    const handleDropChange = async (event) => {
        const selectedRole = Number(event.target.value);
        handleTreeSelection(selectedRole);
    }

    const handleTreeSelection = async (selectedRole) => {
        if (selectedRole === 1 && !ragObject?.user_rag_id) {
            //call once if rag id not in sessionstorage
            const rag_id = await getUploadedFileList();
            RAGParams.user_rag_id = rag_id;
        }

        setIsSelectedRole(selectedRole);
        RAGParams.role_id = selectedRole;

        if (selectedRole === 2) {
            groups();
        }
        props.handleSelectionRAG(RAGParams);
    }

    const getUploadedFileList = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BASE_URL + `/user/${userId}/files`, {})
                .then((response) => {
                    resolve(response.data[0].user_vector_id);
                }).catch((error) => {
                    console.error("ERROR ", error);
                });
        });
    }

    const handleSelection = (event) => {
        setIsSelectedFolder(event.target.value);
        RAGParams.role_id = isSelectedRole;
        RAGParams.org_rag_id = event.target.value;
        props.handleSelectionRAG(RAGParams);
    }

    const groups = () => {
        axios
            .get(process.env.REACT_APP_GET_GROUPS_URL +`/${roles}`, {})
            .then((response) => {
                const folders = response.data.filter(folder => (folder.children.length != 0) && folder );
                setOrgData(folders);
                setIsSelectedFolder(ragObject.role_id === 2 && ragObject.rag_id);
            }).catch((error) => {
                console.error("ERROR ", error);
            });
    }

    return (
        <>
            <div>
                <label className="ddl-label">Select type</label>
                <select className="form-select" id="RAGRole" value={isSelectedRole} onChange={(event) => handleDropChange(event)} aria-label="Floating label select example" title="RAG Role">
                    <option value={0}>Select Type</option>
                    {
                        (ConfigData.ROLES_BASE_ACCESS.UPLOAD_FILE_OPTION.filter(item => roles.find(x => x === item)).length) ? (
                            <option value='1'>User</option>
                        ) : (null)
                    }
                    {
                        (ConfigData.ROLES_BASE_ACCESS.VIEW_ORG_FILE_UPLOAD_TAB.filter(item => roles.find(x => x === item)).length) ? (
                            <option value='2'>Organisation folder</option>
                        ) : (null)
                    }
                    {/* {ConfigData.RAG_FILE_SELECTION.map((item, index) => (
                        <option key={index} value={item.id}>{item.name}</option>
                    ))} */}
                </select>
                {
                    (isSelectedRole === 2) ? (
                        <>
                            <label className="ddl-label">Select folder</label>
                            <select className="form-select" id="folders" value={isSelectedFolder} onChange={(event) => handleSelection(event)} aria-label="Floating label select example" title="RAG Role">
                                <option value={0}>Select Folder</option>
                                {orgData.map((item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                ))}
                            </select>
                        </>
                    ) : (null)
                }
            </div>
        </>
    )
}

export default RAGFileView;