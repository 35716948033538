// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#loading-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
.load-text{
  color: #80808085;
}  `, "",{"version":3,"sources":["webpack://./src/shared/spinner/spinner.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,OAAO;IACP,MAAM;EACR;AACF;EACE,gBAAgB;AAClB","sourcesContent":["\r\n#loading-wrapper {\r\n    position: fixed;\r\n    width: 100%;\r\n    height: 100%;\r\n    left: 0;\r\n    top: 0;\r\n  }\r\n.load-text{\r\n  color: #80808085;\r\n}  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
