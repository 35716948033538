// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../../public/tangoe-icon-new.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
    background-color: #000;
    height: 100vh;
    padding: 10px;
}

.tangoe-logo {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    height: 75px;
    background-repeat: no-repeat;
    background-size:  159px 50px;
    /* margin-left: 10px; */
}
.title {
    color: #20A65F;
    font-size: 80px;
    padding-bottom: 10px;
}
.ai{
    color: #ffffff;
    font-size: 79px;
}

.sso-outerbox{
    display: flex;
    justify-content: center;
}

.sso-box {
    box-shadow: 0px 0px 165px 1px rgba(123, 152, 232, 0.78);
    margin-top: 10%;
    padding: 28px 65px;
    border-radius: 20px;
}

.sso-btn{
    border-radius: 6px;
    color: #00cfff !important;
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/Component/Login/login.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,yDAA8D;IAC9D,YAAY;IACZ,4BAA4B;IAC5B,4BAA4B;IAC5B,uBAAuB;AAC3B;AACA;IACI,cAAc;IACd,eAAe;IACf,oBAAoB;AACxB;AACA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IAGI,uDAAuD;IACvD,eAAe;IACf,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,iBAAiB;AACrB","sourcesContent":[".main {\r\n    background-color: #000;\r\n    height: 100vh;\r\n    padding: 10px;\r\n}\r\n\r\n.tangoe-logo {\r\n    background-image: url(\"./../../../public/tangoe-icon-new.png\");\r\n    height: 75px;\r\n    background-repeat: no-repeat;\r\n    background-size:  159px 50px;\r\n    /* margin-left: 10px; */\r\n}\r\n.title {\r\n    color: #20A65F;\r\n    font-size: 80px;\r\n    padding-bottom: 10px;\r\n}\r\n.ai{\r\n    color: #ffffff;\r\n    font-size: 79px;\r\n}\r\n\r\n.sso-outerbox{\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n\r\n.sso-box {\r\n    -webkit-box-shadow: 0px 0px 165px 1px rgba(123, 152, 232, 0.78);\r\n    -moz-box-shadow: 0px 0px 165px 1px rgba(123, 152, 232, 0.78);\r\n    box-shadow: 0px 0px 165px 1px rgba(123, 152, 232, 0.78);\r\n    margin-top: 10%;\r\n    padding: 28px 65px;\r\n    border-radius: 20px;\r\n}\r\n\r\n.sso-btn{\r\n    border-radius: 6px;\r\n    color: #00cfff !important;\r\n    font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
