// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.note{
    margin-top: 24px;
    font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/Component/multiple-prompt/popup.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":["\r\n.note{\r\n    margin-top: 24px;\r\n    font-size: 18px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
