// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.note-instruction {
    font-size: 12px;
    margin-top: 24px;
}
.main-table{
    padding-top: 5px;
}
.table-row-width{
    width: 100%;
    display: flex;
}

.td-text-wrap{
    width: 325px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.table-hr-scroll{
    height: 30vh;
    overflow-y: auto;
}

.section-divider {
    border-right: 1px solid #6633CA;
}

.loading {
    height: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/Component/Prompt/upload-file/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".note-instruction {\r\n    font-size: 12px;\r\n    margin-top: 24px;\r\n}\r\n.main-table{\r\n    padding-top: 5px;\r\n}\r\n.table-row-width{\r\n    width: 100%;\r\n    display: flex;\r\n}\r\n\r\n.td-text-wrap{\r\n    width: 325px;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n    white-space: nowrap;\r\n}\r\n\r\n.table-hr-scroll{\r\n    height: 30vh;\r\n    overflow-y: auto;\r\n}\r\n\r\n.section-divider {\r\n    border-right: 1px solid #6633CA;\r\n}\r\n\r\n.loading {\r\n    height: 100px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
