import ConfigData from './../../config.json';
import './popup.css';
import React, { useEffect, useState, useRef } from "react";
import { Modal } from 'react-bootstrap'

const MultiplePromptModel = (props) => {
    const [isShow, invokeModal] = useState(false);
    const hiddenFileInput = useRef(null);
    const allwedFileExt = [...ConfigData.FILE_EXTENSION.MULTI_PROMPT_FILE_EXTENSION];

    useEffect(() => {
        props.callMultiPromptModel.current = handleModel;
    }, []);

    const handleModel = (flag = true) => {
        return invokeModal(flag);
    }

    const handleClick = (event) => {
        hiddenFileInput.current.click(event);
        event.preventDefault();
    };

    // Multiple prompt upload functionality
    const handleChange = async (event) => {
        const fileExtCheck = event.target.value.split('.');
        if (allwedFileExt.includes(fileExtCheck[fileExtCheck.length - 1])) {
            props.handleFileCallback(event);
            event.target.value = null;
            handleModel(false);
        } else {
            event.target.value = null;
            alert(`Sorry, you are not allowed to upload ${fileExtCheck[fileExtCheck.length - 1]} file`);
            return;
        }
    };

    return (
        <Modal show={isShow}>
            <Modal.Header>
                <Modal.Title>Upload Multiple Prompts</Modal.Title>
                <button type="button"
                    className="btn-close"
                    onClick={() => handleModel(false)}
                    title='Close'
                    aria-label="Close">
                </button>
            </Modal.Header>
            <Modal.Body>
                <input
                    type="file"
                    onChange={handleChange}
                    ref={hiddenFileInput}
                    style={{ display: "none" }} // Make the file input element invisible
                    accept={ConfigData.BROWSE_FILE_EXTENSION.UPLOAD_MULTI_PROMPT}
                />
                <div>
                    <button className='btn btn-primary'
                        onClick={handleClick} >
                        Upload file
                    </button>
                </div>
                <div className="text-dark note">
                    <strong>Instruction</strong>
                    <ol>
                        <li dangerouslySetInnerHTML={{ __html: ConfigData.MESSAGES.MULTI_PROMPT_MODEL_INSTRUCTION_MSG }}></li>
                    </ol>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button"
                    className="btn btn-outline-danger"
                    onClick={() => handleModel(false)}
                    title='Close'>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    )
}
export default MultiplePromptModel;