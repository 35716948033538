// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tree-scroll{
    height: 30vh;
    overflow-y: auto;
}
.view-text{
    color: #F37822;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Component/rag-files-view/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,eAAe;AACnB","sourcesContent":[".tree-scroll{\r\n    height: 30vh;\r\n    overflow-y: auto;\r\n}\r\n.view-text{\r\n    color: #F37822;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
