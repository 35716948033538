import ConfigData from './../../../config.json';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap'
import Toaster from "../../../shared/toaster";
import Spinner from "../../../shared/spinner";

const AddPrompt = (props) => {
    const [isShow, invokeModal] = useState(false);
    const [count, setCount] = useState(0);
    const initialState = {
        title: '',
        prompt_input: ''
    };
    const empid = window.sessionStorage.getItem('user_id');
    const [pageLoader, setPageLoader] = useState(false);
    const [inputField, setInputField] = useState({
        title: '',
        prompt_input: ''
    })

    useEffect(() => {
        props.callAddModel.current = handleModel;
    }, []);

    const handleModel = (flag = true) => {
        setCount(0);
        setInputField({ ...initialState })
        return invokeModal(flag);
    }

    const inputsHandler = (e) => {
        if (e.target.name === "prompt_input"){
            setCount(e.target.value.trim().length);
        }
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    }

    const submitForm = (event) => {
        event.preventDefault();
        let url = "";
        (props.promptFlag === 2)
            ? url = process.env.REACT_APP_ADD_UPDATE_STANDARD_PROMPT_URL
            : url = process.env.REACT_APP_UPDATE_PROMPT

        setPageLoader(true);
        const prompt_id = new Date().getTime();
        const response = axios
            .put(url, {
                user_id: empid,
                prompt_title: inputField.title,
                prompt_desc: inputField.prompt_input,
                prompt_id: prompt_id
            })
            .then((response) => {
                props.handleNewPromptCallback(props.promptFlag);
                setPageLoader(false);
                handleModel(false);
                Toaster({ type: 'success', message: 'Prompt added successfully' });
                // setInputField({ ...initialState })
            })
            .catch((error) => {
                setPageLoader(false);
                console.error('ERROR ', error.message);
                Toaster({ type: 'error', message: error.message });
            });
    }

    return (
        <Modal show={isShow}>
            <Modal.Header>
                <Modal.Title>Add New Prompt</Modal.Title>
                <button type="button"
                    className="btn-close"
                    onClick={() => handleModel(false)}
                    title="Close"
                    aria-label="Close new">
                </button>
            </Modal.Header>
            <form onSubmit={submitForm}>
                <Modal.Body>
                    <div className="form-floating mb-3">
                        <input type="text"
                            className="form-control"
                            id="promptTitle"
                            name="title"
                            onChange={inputsHandler}
                            value={inputField.title}
                            placeholder="Prompt title" />
                        <label htmlFor="promptTitle">Prompt Title
                            <strong className="text text-danger">*</strong>
                        </label>
                    </div>
                    <div className="form-floating mt-4">
                        <textarea
                            className="form-control"
                            placeholder="Write Your Prompt"
                            name="prompt_input"
                            onChange={inputsHandler}
                            value={inputField.prompt_input}
                            id="inputPrompt"></textarea>
                        <label htmlFor="inputPrompt">Write Your Prompt
                            <strong className="text text-danger">*</strong>
                        </label>
                    </div>
                    <div>
                        <span className={count <= ConfigData.DEFAULT_CHARACTER_LIMIT ? 'text text-success' : 'text text-danger'}> Character limit  <strong>{count}/ {ConfigData.DEFAULT_CHARACTER_LIMIT}</strong> </span>
                    </div>
                    <div>
                        <span className="text text-danger"><strong>*</strong> Fields are mandatory.</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit"
                        className="btn btn-outline-success"
                        disabled={((count <= 0 || count > ConfigData.DEFAULT_CHARACTER_LIMIT) || (!inputField.title.length || inputField.title.length >  ConfigData.DEFAULT_PROMPT_TITLE_CHARACTER_LIMIT)) ? true : false}>
                        Save</button>
                    <button type="button"
                        className="btn btn-outline-danger"
                        onClick={() => handleModel(false)}>
                        Cancel
                    </button>
                </Modal.Footer>
            </form>
            <Spinner spinnerLoad={pageLoader} />
        </Modal>
    )
}
export default AddPrompt;