import "./App.css";
import "./";
import Login from "./Component/Login";
import React, { useState } from "react";
import { MsalProvider } from "@azure/msal-react";
import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

if (typeof window !== "undefined") {
  injectStyle();
}

// Azure SSO login code
function App({msalInstance}){
  return (
      <MsalProvider instance={msalInstance}>
           <Login/>
           <ToastContainer />
      </MsalProvider>
  )
}

// Without Azure SSO login code
// function App() {
//   if (user_id) return <><Chat /><ToastContainer /></>;
//   else return <Login />;
// }
export default App;
