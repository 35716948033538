import ConfigData from "./../../config.json";
import './style.css';
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useRolesContext } from "../../shared/context/roles-context";

const RAGParams = {
    "role_id": 0,
    "user_rag_id": '0',
    "org_rag_id": 0
}

  const ragSessionObj = {
    toggle: false,
    topic_id: '',
    rag_id: 0,
    role_id: 0,
    role: ''
  };

const RAGFileView = ( props) => {
    const userId = sessionStorage.getItem('user_id');
    const ragObject = JSON.parse(sessionStorage.getItem('ragObject'));
    const roles = useRolesContext();
    const [isSelectedRole, setIsSelectedRole] = useState(0);
    const [isSelectedFolder, setIsSelectedFolder] = useState('');
    const [orgData, setOrgData] = useState([]);


    useEffect(() => {
       
       // console.log('View - ', roles);
         console.log('View - ragObject in RAGFILESVIEW ', ragObject);
        !!ragObject && setIsSelectedRole(ragObject.role_id);
        console.log('props.message ',props.message);

       
        groups();
       // console.log('ragObject.topic_id',ragObject.topic_id);
        // if(props.message==undefined){
           

        // }
        // else{
            
        //     getGroupByTopic1(props.message);
        // }
        if(ragObject){
            if(ragObject.topic_id==null){    
            }
            else{         
               // getGroupByTopic1(ragObject.topic_id);
                setIsSelectedFolder(ragSessionObj.rag_id);
            }
        }


       //handleSelection1();
    }, [props]);

    const handleDropChange = async (event) => {
       
        const selectedRole = Number(event.target.value);
        console.log('handleDropChange',event.target.value);
        handleTreeSelection(selectedRole);
    }

    const handleTreeSelection = async (selectedRole) => {
        if (selectedRole === 1 && !ragObject?.user_rag_id) {
            //call once if rag id not in sessionstorage
            const rag_id = await getUploadedFileList();
            RAGParams.user_rag_id = rag_id;
        }

        setIsSelectedRole(selectedRole);
        RAGParams.role_id = selectedRole;

        if (selectedRole === 2) {
            groups();
        }
        props.handleSelectionRAG(RAGParams);
    }

    const getUploadedFileList = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_BASE_URL + `/user/${userId}/files`, {})
                .then((response) => {
                    resolve(response.data[0].user_vector_id);
                }).catch((error) => {
                    console.error("ERROR ", error);
                });
        });
    }

    const handleSelection = (event) => {
        console.log('handleSelection', event);
        console.log('handleSelection',event.target.value);
        setIsSelectedFolder(event.target.value);
        RAGParams.role_id = isSelectedRole;
        RAGParams.org_rag_id = event.target.value;
        console.log('handleSelection RAGParams',RAGParams);
        props.handleSelectionRAG(RAGParams);
    }

    const handleSelection1 = () => {
        const ragObject = JSON.parse(sessionStorage.getItem('ragObject'));
        console.log('handleSelection1 ', ragObject);
        // setIsSelectedFolder(event.target.value);
        
        setIsSelectedFolder(ragObject.org_rag_id);
        // RAGParams.role_id = isSelectedRole;
        // RAGParams.org_rag_id =ragObject.org_rag_id;
        // props.handleSelectionRAG(RAGParams);

    }


    const groups = () => {
        console.log('roles in ragviewcomponent',roles);
        axios
            .get(process.env.REACT_APP_GET_GROUPS_URL +`/${roles}`, {})
            .then((response) => {
                const folders = response.data.filter(folder => (folder.children.length != 0) && folder);
               // console.log('folders',folders);
                setOrgData(folders);
                // console.log('ragObject.rag_id', ragObject.rag_id);
                setIsSelectedFolder(ragObject.role_id === 2 && ragObject.rag_id);
            }).catch((error) => {
                console.error("ERROR in ragfileview component", error);
            });
    }


    const getGroupByTopic = async (strgroupId) => {
        //  const getGroupByTopic = asyn
        console.log('inside getGroupByTopic in ragf');
        console.log(strgroupId);
        const answer_array = strgroupId.split('-');
        const groupId=answer_array[0];
        const topicId=answer_array[1];
        console.log(groupId);
        console.log(topicId);
       
      

        sessionStorage.removeItem("ragObject");
         // Create new file upload  session
         ragSessionObj.toggle = true;
         ragSessionObj.rag_id = groupId;
         ragSessionObj.topic_id = topicId;
         ragSessionObj.role_id = 2;
         ragSessionObj.role =  'Org' ;        
         sessionStorage.setItem('ragObject', JSON.stringify(ragSessionObj));
 
            setIsSelectedFolder(groupId);


    }
    
   
   const getGroupByTopic1 = async (topicId) => {
          
       if(!topicId=="") {

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/topic/${topicId}/group`, {})
        .then((response) => {
           console.log('getGroupByTopic response', response);

        const getGroup = response.data;
        console.log('getGroupByTopic ', response);
        
        sessionStorage.removeItem("ragObject");
        const selectedChatId = topicId;

        // if (selectedNode.role_id === 0 || (selectedNode.role_id === 2 && selectedNode.org_rag_id == "0")) {
        //   ragSessionObj.toggle = false;
        //   ragSessionObj.role_id = selectedNode.role_id;
        //   sessionStorage.setItem('ragObject', JSON.stringify(ragSessionObj));
        console.log('getGroupByTopic group_vector_id ', response.data['group_vector_id']);

        // Create new file upload  session
        ragSessionObj.toggle = true;
        ragSessionObj.rag_id = response.data['group_vector_id'];
        ragSessionObj.topic_id = selectedChatId;
        ragSessionObj.role_id = 2;
        ragSessionObj.role =  'Org' ;
        
   // ragSessionObj.role = (selectedNode.role_id === 2) ? 'Org' : 'User';
    sessionStorage.setItem('ragObject', JSON.stringify(ragSessionObj));


        // setChatHistory(topic_list.reverse());
          // setChatTitle(topic_list.find(o => (o.topic_id == selected) && o.topic_desc));
          
           setIsSelectedFolder(ragSessionObj.rag_id);
      })
      .catch((error) => {
          console.error('ERROR ', error);
        
        const getGroupErrorMsg = error.message;
        console.error('ERROR ', getGroupErrorMsg);
          if (getGroupErrorMsg=="Request failed with status code 404")
          {
             console.error('ERROR inside if condition'); 
//               sessionStorage.removeItem("ragObject");
//                ragSessionObj.toggle = false;
//                 ragSessionObj.rag_id = 0;
//                 ragSessionObj.topic_id = "";
//                 ragSessionObj.role_id = 0;
//                 ragSessionObj.role =  "" ;
        
// //    // ragSessionObj.role = (selectedNode.role_id === 2) ? 'Org' : 'User';
//     sessionStorage.setItem('ragObject', JSON.stringify(ragSessionObj));

          }
          
        //Toaster({ type: 'error', message: error.message });
      });
    }
      

  }



    return (
        <>
            <div>
                <label className="ddl-label">Select type</label>
                <select className="form-select" id="RAGRole" value={isSelectedRole} onChange={(event) => handleDropChange(event)} aria-label="Floating label select example" title="RAG Role">
                    <option value={0}>Select Type</option>
                    {
                        (ConfigData.ROLES_BASE_ACCESS.UPLOAD_FILE_OPTION.filter(item => roles.find(x => x === item)).length) ? (
                            <option value='1'>User</option>
                        ) : (null)
                    }
                    {
                        (ConfigData.ROLES_BASE_ACCESS.VIEW_ORG_FILE_UPLOAD_TAB.filter(item => roles.find(x => x === item)).length) ? (
                            <option value='2'>Organisation folder</option>
                        ) : (null)
                    }
                    {/* {ConfigData.RAG_FILE_SELECTION.map((item, index) => (
                        <option key={index} value={item.id}>{item.name}</option>
                    ))} */}
                </select>
                {
                    (isSelectedRole === 2) ? (
                        <>
                            <label className="ddl-label">Select folder</label>
                            <select className="form-select" id="folders" value={isSelectedFolder} onChange={(event) => handleSelection(event)} aria-label="Floating label select example" title="RAG Role">
                                <option value={0}>Select Folder</option>
                                {orgData.map((item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                ))}
                            </select>
                        </>
                    ) : (null)
                }
            </div>
        </>
    )
}

export default RAGFileView;